/******************
** \reset select 2
******************/

.select2-container--open,
.select2-dropdown--below,
.select2-drop {
    z-index: 99;

}

.select2-container .select2-choice {
    position: relative;
    z-index: 2;
}

.select2-container {
    max-width: 100%;
    z-index: 9;
}

.select2-container li:before {
    display: none;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    padding: 6px 10px;
    border: 0;
}

.select2-dropdown,
.select2-search--dropdown {
    border-color: #e0e6ea;
}

.select2-container--default .select2-results__option {
    position: relative;
    color: #4b4342;
    font-weight: 500;
    font-size: 17px;
    padding: 13px 10px;
    line-height: 20px;
    border-top: 1px solid #f1f1f1;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.select2-search--dropdown,
.select2-results .select2-result-label {
    padding: 0;
}

.select2-container--default .select2-results__option[aria-selected="true"],
.select2-container--default
.select2-results__option--highlighted[aria-selected] {
    background-color: transparent;
    color: var(--primary-color);
}

.select2-container--default .select2-selection--single {
    position: relative;
    width: 100%;
    display: block;
    color: #5c6770;
    font-size: 14px;
    line-height: 30px;
    padding: 10px 18px;
    height: 54px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 2px solid #e6e8ed;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.select2-container--default
.select2-selection--single
.select2-selection__arrow {
    width: 36px;
    height: 100%;
    background: none;
}

.select2-container--default
.select2-selection--single
.select2-selection__arrow
b {
    display: block;
    left: 0;
    top: -1px;
    margin: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent !important;
    background: none;
    background-color: inherit;
}

.select2-container--default
.select2-selection--single
.select2-selection__arrow
b:before {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    content: "\f107";
    opacity: 0.25;
    font-family: "Font Awesome 5 Free", sans-serif;
    line-height: 20px;
    font-size: 17px;
    color: inherit;
    font-weight: 900;
}

.chosen-container-single .chosen-single div:after {
    top: 50%;
    margin-top: -10px;
    line-height: 20px;
    right: 20px; 
    color: #2F2D51;
    content: "\f17c";
    font-family: 'Flaticon';
    font-size: 10px;
}

.chosen-container-single .chosen-single span{
    min-width: 80px;
}

.chosen-container .chosen-drop,
.chosen-container .chosen-results{
    border: 0 !important;
    outline: none !important;
    border-radius: 0px 0px 8px 8px !important;
    margin-top: -3px;
}